<!--门禁模块--道闸详情页-->
<template>
  <div id="barrierGateDetails" v-loading="spinning" :element-loading-text="$t('doorDetails.a1')">
    <!-- 头部标题 -->
    <div class="header">
      <div class="header-header">
        <span>{{ data.name }}</span>
        <div class="switch" @click.stop>
          <a-switch v-model="changeState" :default-checked="state" :loading="loading" @change="onChange"/>
        </div>
      </div>
      <a-icon type="close" :style="{ fontSize: '20px', color: '#7682CE' }" @click="goDack"/>
    </div>
    <!-- 内容区域 -->
    <div class="main">
      <!-- 基本信息 -->
      <my-info :data="data.baseInfo" @updateName="updateName" style="margin-bottom:20px"></my-info>
      <my-title :title="title1"></my-title>
      <my-table></my-table>
      <my-title :title="title2"></my-title>
      <a-row type="flex" class="row" align="bottom">
        <a-col class="col" :span="4">
          <span>{{ $t("doorDetails.a87") }}</span>
          <a-input />
        </a-col>
        <a-col class="col" :span="4">
          <span>{{ $t("doorDetails.a89") }}</span>
          <a-input />
        </a-col>
        <a-col class="col" :span="4">
          <span>{{ $t("doorDetails.a90") }}</span>
          <a-input />
        </a-col>
        <a-col class="col" :span="3" :offset="9">
          <a-button type="primary" style="width: 100%" ghost>
            {{ $t("doorDetails.a100") }}
          </a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import info from './components/baseInfo'
import tabletitle from '../../components/Title/tabletitle'
import barrierGateTable from './components/barrierGateTable'
import { getbarrierGateDetails, doorSwitch } from '../../api/door'

export default {
  name:'barrierGateDetails',
  data() {
    return {
      spinning: false,
      changeState: false,
      loading: false,
      data: {},
      title1: this.$t("doorDetails.a91"),
      title2: this.$t("doorDetails.a92")
    }
  },
  computed: {
    state: function () {
      if (this.$route.query.pv == 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    goDack() {
      this.$router.go(-1)
    },
    // 页面开关操作方法
    onChange(checked) {
      if (checked) {
        this.loading = true
        let data = new FormData()
        data.append('id', this.data.id)
        data.append('pv', this.$route.query.pv)
        doorSwitch(data)
          .then((res) => {
            // console.log(res)
            let { errorCode } = res
            if (errorCode == '00') {
              this.loading = false
              this.$message.success(this.$t('doorDetails.a93'))
              setTimeout(() => { this.changeState = false }, 5000)
            } else if (errorCode == '02') {
              this.changeState = false
              this.loading = false
              this.$message.error(this.$t('doorDetails.a94'))
            } else {
              return false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        return false
      }
    },
    // 获取读卡器详情页面数据的方法
    getData() {
      this.spinning = true
      let barrierGateId = this.$route.query.id
      getbarrierGateDetails({ barrierGateId })
        .then((res) => {
          this.spinning = false
          // console.log("道闸数据", res.data)
          let { data } = res
          this.data = data
        })
        .catch((err) => {
          this.spinning = false
          console.log("道闸数据获取失败")
        });
    },
    // 基本信息名称修改成功后同时修改父组件中的名称
    updateName(data) {
      this.data.name = data
      this.data.baseInfo.name = data
    },
  },
  components: {
    'my-info': info,
    'my-title': tabletitle,
    'my-table': barrierGateTable
  },
};
</script>

<style scoped>
#barrierGateDetails {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  background: #ffffff;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-header {
  display: flex;
  align-items: center;
}
.header-header span {
  margin-right: 30px;
}
.switch {
  display: flex;
  align-items: center;
}
.main {
  width: 100%;
  height: calc(100% - 68px);
  padding: 20px 20px 20px 20px;
  white-space: nowrap;
  overflow: auto;
}
.row {
  font-size: 14px;
  border: 1px solid #dedfdf;
  border-top: none;
}
.col {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 10px;
}
.col span {
  margin-bottom: 5px;
}
</style>