<!--门禁模块--道闸详情页--操作表格-->
<template>
  <div id="detailstabel">
    <table>
      <tr class="tabletitle">
        <td>{{ $t("doorDetails.a2") }}</td>
        <td>{{ $t("doorDetails.a3") }}</td>
        <td>{{ $t("doorDetails.a4") }}</td>
      </tr>
      <tr class="tablecontent top buttom">
        <td>{{ $t("doorDetails.a5") }}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
#detailstabel {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-bottom: 1px solid #dedfdf;
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
.top {
  border-top: 1px solid #dedfdf;
}
button {
  width: 120px;
}
img {
  width: 36px;
  margin-right: 15px;
}
</style>