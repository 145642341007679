<!--门禁模块--详情页基本信息组件-->
<template>
  <div id="baseinfo">
    <div class="row">
      <span>{{ $t("doorDetails.a6") }}</span>
      <p>
        {{ data.name }}
        <a-icon type="form" @click="openAmendName" :style="{ fontSize: '18px', color: '#7682CE','margin-left':'10px' }"/>
      </p>
    </div>
    <div class="row">
      <span>{{ $t("doorDetails.a7") }}</span>
      <p>{{ data.customerName }}</p>
    </div>
    <div class="row">
      <span>{{ $t("doorDetails.a8") }}</span>
      <p>{{ data.typeDesc }}</p>
    </div>
    <div class="row">
      <span>{{ $t("doorDetails.a9") }}</span>
      <p>{{ data.siteName }}</p>
    </div>
    <div class="row">
      <span>{{ $t("doorDetails.a10") }}</span>
      <p>{{ data.tenantName }}</p>
    </div>

    <!--名称修改-->
    <a-modal :title="$t('doorDetails.a104')" centered :width="400" :visible="nameReviseVisible" @cancel="amendCancel" :destroyOnClose="true" v-drag>
      <template slot="footer">
        <a-button key="back" @click="amendCancel">
          {{ $t("doorDetails.a106") }}
        </a-button>
        <a-button key="submit" type="primary" @click="updateName">
          {{ $t("doorDetails.a107") }}
        </a-button>
      </template>
      <div v-loading="loading" :element-loading-text="$t('doorDetails.a1')">
        <div class="main">
          <p class="name">{{ $t("doorDetails.a105") }}</p>
          <a-input v-model="name" :maxLength="30" allow-clear style="width: 250px" :placeholder="$t('doorDetails.a108')" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { baseInfoUpdataName } from '../../../api/door'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      nameReviseVisible:false,
      name:''
    }
  },
  methods: {
    // 打开名称修改弹框
    openAmendName() {
      this.name = this.data.name
      this.nameReviseVisible = true
    },
    // 关闭名称修改弹框
    amendCancel() {
      this.nameReviseVisible = false
    },
    // 修改名称的方法
    updateName() {
      this.loading = true
      let data = new FormData()
      data.append('id', this.data.id)
      data.append('type', this.data.type)
      data.append('name', this.name)
      baseInfoUpdataName(data)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.errorCode == '00') {
            this.$emit('updateName', this.name)
            this.$message.success(res.msg)
            this.amendCancel()
          } else if (res.errorCode == '02') {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#baseinfo {
  width: 100%;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 10px 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px #bdbcbc;
  overflow: hidden;
}
p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.row {
  margin-right: 40px;
}
.row span {
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row p {
  font-size: 16px;
  white-space: nowrap;
}
.name{
  margin-bottom: 9px;
  font-size: 14px;
}
</style>